@import 'src/styles/mixins';

.container {
  @include smParagraph;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $primary100;
  position: relative;
  margin: 16px 0 30px;
}

.containerWithoutBackButton {
  justify-content: flex-end;
}
