@import 'styles/colors';

.categoryWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 14px;
  cursor: pointer;
  margin-bottom: 20px;

  & > h3 {
    color: $primary100;
  }
}
