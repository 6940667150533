@import 'src/styles/media';
@import 'src/styles/variables';
@import 'styles/mixins';

.primaryButton {
  max-width: 408px;

  @include laptop {
    margin-bottom: 0;
  }
}

.description {
  border: 1px solid $primary20;
  border-radius: 4px;
  background: $primary2;
  padding: 16px;
  margin: 16px 0 40px;

  @include laptop {
    padding: 40px 220px;
    margin: 40px 0;
  }
}

.messageTitle {
  @include titleH3;
  color: $secondary100;
}

.message {
  @include mdParagraph;
  color: $secondary80;
  margin: 16px 0 14px;
}

.pageLayoutClassName {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.documentTypeContainer {
  margin-bottom: 24px;
}

.uploadFileButton {
  max-width: 408px;
  margin-top: 40px;
}
