@import 'styles/mixins';
@import 'styles/media';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.description {
  margin: 20px 0;
  text-align: center;
  @include titleH4;
}

.annualValue {
  margin-bottom: 30px;
  text-align: center;
  @include lgParagraph;
}

.buttonsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;

  @include tablet {
    flex-direction: row;
  }
}
