@import 'styles/mixins';
@import 'styles/colors';

.pageWrapper {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  background-image: url('./not-found-bg.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  & > h3 {
    color: $white100Base;
    margin-bottom: 24px;
  }

  & > button {
    background-color: transparent;
    color: $white100Base;
    border: 1px solid $white100Base;

    &:hover:not(:disabled),
    &:focus {
      background-color: $white100Base;
      color: $primary100;
    }
  }

  &::before {
    content: attr(data-bg-message);
    @include robotoBold;
    position: absolute;
    top: 170px;
    display: block;
    font-size: 83px;
    line-height: 97px;
    letter-spacing: 1.5px;
    color: #02696f;
    text-transform: uppercase;
    opacity: 0.4;
    filter: blur(2px);
  }

  @include tablet {
    background-image: url('./not-found-bg-tablet.svg');

    & > h3 {
      margin-bottom: 40px;
    }

    & > button {
      max-width: 180px;
    }

    &::before {
      font-size: 228px;
      line-height: 267px;
      filter: blur(5px);
      letter-spacing: 4px;
      top: 50px;
    }
  }
}

.errorCode {
  @include robotoBold;
  font-size: 70px;
  line-height: 82px;
  margin-bottom: 24px;
  color: $white100Base;
  letter-spacing: 1.5px;
  position: relative;
  z-index: 1;

  @include tablet {
    font-size: 190px;
    line-height: 222px;
    letter-spacing: 4px;
    margin-bottom: 40px;
  }
}
