@import 'src/styles/colors';
@import 'src/styles/mixins';

.content {
  @include form;
  margin-bottom: 32px;
}

.button {
  max-width: 348px;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 23px;
}
