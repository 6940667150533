@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  position: relative;
}

.container .tooltipContent {
  background: $secondary80;
  border-radius: 4px;
  @include robotoSemiNormal;
  @include middleFont;
  color: $white100Base;
  width: 70vw;
  max-width: 279px;
  padding: 16px;
  opacity: 1;
  z-index: 1;

  @include tablet {
    max-width: 408px;
  }
}

.infoIcon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 100%;
    height: auto;
  }
}
