@import 'styles/variables';
@import 'styles/colors';
@import '~reset-css/sass/reset';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
  outline: none;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

button {
  outline: none;
  border: none;
  background-color: transparent;
}

#root {
  display: flex;
  min-height: 100vh;
  justify-content: stretch;
  flex-direction: column;
  font-family: $fontFamilyRoboto;
}

a {
  text-decoration: none;
  color: $primary100;
}
