@import 'styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  @include laptop {
    flex-direction: row;
  }
}

.divider {
  margin: 35px auto;

  @include laptop {
    margin: 0 35px;
  }
}

.checkbox {
  display: block;
}

.inputWrapperClassName {
  width: 20px;
  height: 20px;

  & > svg {
    stroke-width: 4px;
    width: 20px;
    height: 20px;
  }
}
