@import 'src/styles/variables';
@import 'src/styles/mixins';

.actionContainer {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid $secondary20;

  @include tablet {
    border-top: 1px solid $secondary40;
  }
}
