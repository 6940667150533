@import 'styles/mixins';

.bold {
  @include robotoBold;
}

.info {
  @include mdParagraph;
  color: $secondary80;
}

.congratulations {
  margin-bottom: 16px;

  @include laptop {
    margin-bottom: 0;
  }
}
