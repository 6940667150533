@import 'styles/mixins';

.container {
  & > div {
    @include mdParagraph;
  }
}

.title {
  margin-bottom: 9px;
}

.description {
  margin-bottom: 5px;
}

.steps {
  margin-left: 10px;

  & > div {
    @include mdParagraph;
    margin-bottom: 5px;
  }
}

.subStep {
  margin-left: 10px;
}

.guide {
  width: 222px;
  height: auto;
  margin-left: 15px;
  margin-bottom: 5px;

  @include tablet {
    margin-left: 20px;
    width: 250px;
  }
}
