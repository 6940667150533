@import 'styles/media';

.pageWrapper {
  margin: 0 auto;
  @include tablet {
    max-width: 849px;
  }
}

.pageTitle {
  margin-bottom: 16px;
}

.pageDescription {
  margin-bottom: 32px;

  @include tablet {
    margin-bottom: 40px;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include tablet {
    flex-direction: row;
    column-gap: 90px;
  }
}
