@import 'src/styles/colors';
@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  background: $white100Base;
  box-shadow: 0 4px 16px rgba(24, 25, 27, 0.08);
  border-radius: 4px;
  width: 100%;
  max-width: 408px;
}

.priorContainer {
  cursor: pointer;
}

.blockContainer {
  padding: 24px 24px 16px;
}

.contractorNameContainer {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  @include tablet {
    margin-bottom: 8px;
  }
}

.label {
  padding: 2px 8px;
  border-radius: 2px;
  letter-spacing: -0.2px;
  @include smallFont;
  @include robotoSemiNormal;
  color: $white100Base;
  margin-left: 8px;
}

.activeLabel {
  background-color: $primary70;
}

.inactiveLabel {
  background-color: $pink100Base;
}

.updatedAt {
  letter-spacing: -0.2px;
  color: $secondary80;
  @include smallFont;
  @include robotoSemiNormal;
}

.status {
  color: $white100Base;
  text-transform: uppercase;
  padding: 4px 24px;
  margin-bottom: 16px;
  @include smallFont;
  @include robotoBold;
}

.activeStatus {
  background-color: $secondary100;
}

.inactiveStatus {
  background-color: $primary100;
}

.bottomBoxContainer {
  padding: 16px 24px 24px;
}

.variableContainer {
  display: flex;
  justify-content: space-between;
}

.variableTitle {
  @include robotoSemiNormal;
  @include regularFont;
  letter-spacing: -0.2px;
  color: $secondary80;
}

.variableValue {
  letter-spacing: -0.2px;
  @include robotoBold;
  @include regularFont;
  color: $secondary100;
}

.continueButton {
  margin-top: 24px;
}

.notification {
  display: flex;
  padding: 0 24px;
  margin-bottom: 16px;

  & > svg {
    min-width: 24px;
    height: 24px;
    margin-right: 8px;
  }
}

.notificationText {
  & > p {
    @include middleFont;
    font-family: $fontFamilyRoboto;
  }
}
