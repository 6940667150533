@import 'styles/media';
@import 'styles/variables';
@import 'styles/colors';

.tooltipWrapper {
  position: absolute;
  padding: 24px;
  max-width: 311px;
  bottom: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 4px 16px rgba(24, 25, 27, 0.08);
  background-color: $white100Base;
  border-radius: $borderRadiusDefault;
  z-index: $popupZIndex;

  @include tablet {
    .closeIcon {
      display: none;
    }
  }

  &.loginPosition {
    top: 225px;
  }
}

.closeIcon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}
