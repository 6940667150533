@import 'src/styles/media';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;

  @include laptop {
    flex-direction: row;
    justify-content: space-between;
  }
}

.primaryButton {
  max-width: 408px;
  margin-bottom: 24px;

  @include laptop {
    margin-bottom: 0;
  }
}

.secondaryButton {
  max-width: 408px;

  @include laptop {
    max-width: 188px;
  }
}
