@import 'styles/mixins';

.container {
  background: $white100Base;
  box-shadow: 0 4px 16px rgba(24, 25, 27, 0.08);
  border-radius: 4px;
  width: 100%;
  max-width: 300px;
}

.blockContainer {
  padding: 24px 24px 16px;
}

.contractorNameContainer {
  display: flex;
  align-items: center;
  margin-bottom: 22px;

  @include tablet {
    margin-bottom: 26px;
  }
}

.status {
  color: $white100Base;
  text-transform: uppercase;
  padding: 4px 24px;
  margin-bottom: 58px;
  @include smallFont;
  @include robotoBold;
  background-color: $secondary100;
}

.bottomBoxContainer {
  margin-top: auto;
  padding: 16px 34px 24px;
}

.title {
  @include lgSubtitle;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 24px;
}

.description {
  @include smParagraph;
  text-align: center;
}

.marginBottom10 {
  margin-bottom: 10px;
}
