@import 'src/styles/mixins';

.h1 {
  @include titleH1;
}

.h2 {
  @include titleH2;
}

.h3 {
  @include titleH3;
}

.h4 {
  @include titleH4;
}

.marginBottomSmall {
  margin-bottom: 16px;
}

.marginBottomMiddle {
  margin-bottom: 24px;
}

.marginBottomExtraSmall {
  margin-bottom: 8px;
}

