@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  @include smParagraph;
  display: flex;
  justify-content: space-between;
  align-items: center;

  color: $primary100;
  position: relative;

  svg {
    position: absolute;
    top: 4px;
    left: -15px;
  }
}

.back {
  cursor: pointer;
}
