$primary120: #006142;
$primary100: #007a53;
$primary80: #339575;
$primary70: #76bf56;
$primary60: #66af98;
$primary40: #99caba;
$primary30: #b3d7cb;
$primary20: #cce4dd;
$primary10: #e5f2ee;
$primary6: #f0f7f5;
$primary2: #fafcfc;

$secondary100: #18191b;
$secondary80: #464749;
$secondary60: #747576;
$secondary40: #a3a3a4;
$secondary30: #bababb;
$secondary20: #d1d1d1;
$secondary10: #e8e8e8;
$secondary6: #f1f1f1;
$secondary2: #fafafa;

$red100Base: #e10808;
$pink100Base: #ed6f6f;
$white100Base: #ffffff;
$black100Base: #000000;

$green100Base: #00c3ac;
$green110: #00c4ac;

$secondary100Base: #1e95f8;

$orange: #f8a401;

$surfacesLight: #f1f2f4;
$popupBackgroundColor: rgba(24, 25, 27, 0.32);

$menuShadow: 0px 8px 24px rgba(15, 35, 66, 0.2);

$surfacesPrimary: $white100Base;
$primaryBackground: #e5e5e5;

$lightBlue: #d0e4f1;
