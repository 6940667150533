.inputContainer {
  input {
    &:focus {
      &::placeholder {
        opacity: 1;
      }
    }

  }
}
