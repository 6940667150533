@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.title {
  @include regularFont;
  @include robotoBold;
  margin-left: 4px;
  letter-spacing: -0.2px;
  color: $primary100;
}

.menu {
  position: absolute;
  min-width: 208px;
  background: $primary2;
  box-shadow: 0 2px 14px rgba(83, 86, 90, 0.15);
  border-radius: 4px;
  padding: 24px 0;
  top: 32px;
  right: -16px;
  z-index: $manageMenuZIndex;

  @include tablet {
    right: unset;
    left: 0;
    top: 48px;
  }
}

.menuItemContainer {
  display: flex;
  align-items: center;
  padding: 4px 24px;

  svg {
    width: 24px;
    height: auto;
  }

  &:hover {
    background: $primary10;
  }
}

.menuItemContainer:not(:last-child) {
  margin-bottom: 8px;
}

.menuItemTitle {
  @include middleFont;
  @include robotoBold;
  color: $primary100;
  margin-left: 8px;
}
