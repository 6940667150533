@import 'styles/mixins';
@import 'styles/variables';
@import 'styles/colors';

.offerCardWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 261px;
  padding: 24px 0 16px;
  box-shadow: 0px 4px 16px rgba(24, 25, 27, 0.16);
  border-radius: $borderRadiusDefault;
}

.disclaimerWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 0 32px 0;

  & > p {
    overflow-y: scroll;
    max-height: 315px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.offerCardLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.offerCardLabel {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 3px 0;
  margin-bottom: 16px;
  background-color: $secondary100;
  color: $white100Base;
  font-weight: $boldFontWeight;
  text-transform: uppercase;
}

.offerCardProductDescription {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 16px;
  font-weight: $boldFontWeight;
  min-height: 18px;
}

.offerCardTitle {
  margin-bottom: 12px;
}

.offerCardDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 16px;
}

.offerCardDivider {
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
  background-color: $primary20;
}

.offerCardDescriptionList {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  margin-bottom: 24px;
}

.offerCardDescriptionItem {
  display: flex;
  justify-content: space-between;
}

.offerCardDisclaimerButton {
  @include smParagraph;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 7px;
  color: $primary100;
  cursor: pointer;
}

.buttonWrapper {
  padding: 0 16px;
  width: 100%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
