@import 'src/styles/mixins';

.container {
  @include smParagraph;
  color: $primary100;
  cursor: pointer;
  display: inline-block;

  &>svg {
    margin-left: 4px;
    rotate: 180deg;
  }
}

.tooltipTextMargin {
  margin-top: 24px;
}

.tooltipText {
  @include mdParagraph;
  color: $secondary100;
}
