@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include tablet {
    justify-content: normal;
    align-items: center;
  }
}

.titleContainer {
  display: flex;
  align-items: flex-start;

  @include tablet {
    align-items: center;
    max-width: unset;
    margin-right: 16px;
  }
}

.title {
  @include middleFont;
  letter-spacing: -0.5px;

  @include tablet {
    @include largeFont;
  }
}

.tooltip {
  margin-left: 4px;

  @include tablet {
    margin-left: 8px;
  }
}
