@import 'styles/mixins';

.container {
  & > div {
    @include mdParagraph;
  }
}

.title {
  margin-bottom: 7px;
}

.description {
  margin-bottom: 19px;
}

.info {
  margin-bottom: 5px;
}

.steps {
  margin-left: 10px;

  & > div {
    @include mdParagraph;
  }
}
