@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.stepWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 8px;
}

.stepNumber {
  @include smSubtitle;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  border: 1px solid $secondary10;
  border-radius: 50%;
}

.stepText {
  @include mdSubtitle;
}

.current {
  .stepNumber {
    color: $primary100;
    border-color: $primary100;
  }

  .stepText {
    color: $primary100;
  }
}

.completed {
  .stepNumber {
    border-color: $secondary100;
  }
}
