@import 'styles/mixins';

.info {
  @include mdParagraph;
  color: $secondary80;

  @include laptop {
    @include lgParagraph;
  }
}

.lineHeight24 {
  @extend .info;
}

.textBlock {
  margin-bottom: 12px;

  @include tablet {
    margin-bottom: 24px;
  }
}
