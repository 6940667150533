@import 'src/styles/colors';
@import 'src/styles/mixins';

.label {
  position: relative;
  display: flex;
  align-items: center;
  column-gap: 12px;
  max-width: 100%;
  @include commonParagraph;
  margin-bottom: 40px;
  cursor: pointer;

  & > .error {
    position: absolute;
    transform: translateY(100%);
    bottom: 0;
    color: $red100Base;
  }
}

.inputWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  align-self: flex-start;
}

.inputCheckbox {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  cursor: pointer;
}

.labelText {
  @include robotoSemiNormal;
  @include mdParagraph;
  color: $secondary100;
}
