@import 'styles/variables';
@import 'styles/colors';

.popup {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $popupBackgroundColor;
  z-index: $popupZIndex;
}
