@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  background: $primary10;
  border: 1px solid $primary60;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
}

.amount {
  @include extraMiddleFont;
  @include robotoBold;
  margin-bottom: 8px;
  letter-spacing: -0.5px;
  color: $secondary100;

}
