@import 'src/styles/media';
@import 'src/styles/variables';
@import 'styles/mixins';

.financialInfoContainer {
  @include form;
  margin-bottom: 32px;
}

.marginBottom36 {
  margin-bottom: 36px;
  align-items: flex-start;
}

.checkboxesContainerMargin17 {
  margin-top: 17px;
}

.checkboxesContainerMargin35 {
  margin-top: 35px;
}

.checkboxAlignTop {
  align-items: flex-start;
}

.bold {
  font-weight: $boldFontWeight;
}
