@import 'styles/mixins';

.activeText {
  @include mdParagraph;
  color: $primary100;
  cursor: pointer;
}

.marginBottom8 {
  margin-bottom: 8px;
}
