@import 'styles/media';

.pageWrapper {
  flex: 1 1 auto;
  display: flex;
  margin: 0 auto;
  width: 100%;

  @include tablet {
    max-width: 472px;
  }
}
