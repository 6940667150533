@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/variables';

.label {
  @include mdParagraph;
  position: absolute;
  pointer-events: none;
  z-index: 1;
  transform: translate(17px, 18px);
  transition: all $transitionDuration ease-in-out;
}

.labelFloating {
  @include smParagraph;
  color: $secondary60;
  transform: translate(17px, 4px);
}

.iconContainer {
  position: absolute;
  left: 11px;
}

.dataContainer {
  margin: 0 16px 0 32px;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.container {
  position: relative;
}

.option {
  @include robotoBold;
  @include middleFont;
  display: flex;
  align-items: center;
  font-feature-settings: 'ordn' on;
  color: $secondary100;
}

.arrowIconRotate {
  rotate: 180deg;
}

.errorMessage {
  @include errorMessage;
}

.errorLabel {
  color: $red100Base;
}
