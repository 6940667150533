@import 'styles/mixins';
@import 'styles/variables';

.appContainer {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-bottom: 1px solid $primary20;
}

.header {
  display: flex;
  padding: 28px 32px;
  border-bottom: 1px solid $primary20;

  & > svg {
    cursor: pointer;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 16px 32px;
}

.description {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.info {
  @include smParagraph;
}

.outletLoadingContainer {
  opacity: 0;
  max-height: 0;
}
