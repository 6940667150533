@import 'styles/variables';
@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.titleContainer {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  @include tablet {
    margin-bottom: 16px;
  }
}

.profileIcon {
  width: 32px;
  height: auto;
  margin-right: 8px;

  @include tablet {
    width: 42px;
  }
}

.removeBorder {
  border-bottom: none;
}

.editPhoneWrapper {
  @include popupContent;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > button {
    min-width: 0;
    width: max-content;
  }

  @include tablet {
    justify-content: flex-start;
    column-gap: 24px;
  }
}
