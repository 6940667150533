@import 'src/styles/variables';

.container {
  display: flex;
}

.secondaryButton {
  max-width: max-content;
  min-width: unset;
  margin-right: 10px;
}

.primaryButton {
  max-width: 177px;
  margin-left: 24px;
}
