@import 'styles/colors';
@import 'styles/variables';
@import 'styles/mixins';

.link {
  position: relative;
  display: flex;
  column-gap: 7px;
  align-items: center;
  margin-bottom: 14px;
  cursor: pointer;
}

.linkIcon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-15px, -50%);
}

.phoneNumber {
  font-weight: $boldFontWeight;
}

.pageTitle {
  margin-bottom: 16px;
}

.pageDescription {
  margin-bottom: 32px;
}
