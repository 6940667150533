@import 'styles/mixins';

.container {
  padding: 7px;
  border-radius: 4px;
  background: $secondary6;
  max-width: 230px;
  min-width: 230px;
}

.infoContainer {
  display: flex;
  justify-content: flex-end;
}

.infoContainerWithAdditionalText {
  justify-content: space-between;
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 9px 0 21px;
}

.childrenContainer {
  display: flex;
  justify-content: center;
}

.poweredBy {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & > div {
    margin-right: 4px;
  }
}

.select {
  @include smSubtitle;
}

.connectionType {
  @include lgSubtitle;
}

.connectionDescription {
  @include smSubtitle;
}

.infoIcon {
  cursor: pointer;
}

.additionalText {
  @include smSubtitle;
}
