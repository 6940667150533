@import 'styles/variables';
@import 'styles/colors';
@import 'styles/media';
@import 'styles/mixins';

.popupContent {
  @include popupContent;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;

  .closeButton {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}
