@import 'styles/colors';
@import 'styles/media';
@import 'styles/variables';

.wrapper {
  @include tablet {
    margin-top: 40px;
  }
}

.triggerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 48px;
  padding: 0 32px;
  border-top: 1px solid $primary20;
  border-bottom: 1px solid $primary20;
  background-color: $primary2;
  cursor: pointer;

  @include tablet {
    max-width: 848px;
    margin: 0 auto;
    border: 1px solid $primary20;
    border-radius: 4px 4px 0 0;
  }
}

.itemsWrapper {
  padding: 18px 32px;
  border-bottom: 1px solid $primary20;
  background-color: $primary2;

  @include tablet {
    max-width: 848px;
    margin: 0 auto;
    border: 1px solid $primary20;
    border-radius: 0 0 4px 4px;
  }
}

.menuItem {
  text-transform: uppercase;
}
