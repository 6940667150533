@import 'colors';

// Font
$seminormalFontWeight: 400;
$normalFontWeight: 500;
$semiboldFontWeight: 600;
$boldFontWeight: 700;
$extraLightFOntWeight: 200;

$fontFamilyRoboto: 'Roboto', 'sans-serif';

$fontColor: $secondary100;
$h1LetterSpacing: -1.2px;
$h2LetterSpacing: -1.2px;
$h3LetterSpacing: -0.5px;
$h4LetterSpacing: 0.3px;

/* Desktop Heading */
$desktopH1FontSize: 40px;
$desktopH1LineHeight: 120%;

$desktopH2FontSize: 32px;
$desktopH2LineHeight: 125%;

$desktopH3FontSize: 24px;
$desktopH3LineHeight: 117%;

$desktopH4FontSize: 18px;
$desktopH4LineHeight: 133%;

/* Mobile Heading */
$mobileH1FontSize: 32px;
$mobileH1LineHeight: 125%;

$mobileH2FontSize: 28px;
$mobileH2LineHeight: 143%;

$mobileH3FontSize: 20px;
$mobileH3LineHeight: 120%;

$mobileH4FontSize: 16px;
$mobileH4LineHeight: 125%;

/* Body */
$defaultLetterSpacing: -0.2px;
$lgLineHeight: 150%;
$mdLineHeight: 129%;
$smLineHeight: 150%;

// Other
$borderRadiusDefault: 4px;
$transitionDuration: 0.25s;

$fontFamilyMonsterratRegular: 'Montserrat', 'sans-serif';
$fontFamilyMonsterratBold: 'Montserrat', 'sans-serif';

$defaultErrorColor: $red100Base;
$labelZIndex: 1;
$inputDropdownIndex: 9;
$manageMenuZIndex: 97;
$infoTooltipIndex: 98;
$pageNotificationZIndex: 99;
$popupZIndex: 100;

$paymentHistoryGridTemplate: 30% 36% 16% 14%;
