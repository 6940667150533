@import 'colors';
@import 'variables';
@import 'media';

@mixin smallFont {
  font-size: 12px;
  line-height: 18px;
}

@mixin regularFont {
  font-size: 14px;
  line-height: 18px;
}

@mixin middleFont {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.2px;
}

@mixin extraMiddleFont {
  font-size: 20px;
  line-height: 24px;
}

@mixin largeFont {
  font-size: 24px;
  line-height: 28px;
}

@mixin robotoBold {
  font-family: $fontFamilyRoboto;
  font-weight: $boldFontWeight;
}

@mixin robotoSemiNormal {
  font-family: $fontFamilyRoboto;
  font-weight: $seminormalFontWeight;
}

@mixin extraLight {
  font-family: $fontFamilyRoboto;
  font-weight: $extraLightFOntWeight;
}

@mixin commonTitle {
  @include robotoBold;
  color: $fontColor;
}

@mixin commonSubtitle {
  @include robotoBold;
  color: $fontColor;
  letter-spacing: $defaultLetterSpacing;
}

@mixin commonParagraph {
  color: $fontColor;
  letter-spacing: $defaultLetterSpacing;
  @include robotoSemiNormal;
}

@mixin titleH1 {
  @include commonTitle;
  font-size: $mobileH1FontSize;
  line-height: $mobileH1LineHeight;
  letter-spacing: $h1LetterSpacing;

  @include tablet {
    font-size: $desktopH1FontSize;
    line-height: $desktopH1LineHeight;
  }
}

@mixin titleH2 {
  @include commonTitle;
  font-size: $mobileH2FontSize;
  line-height: $mobileH2LineHeight;
  letter-spacing: $h2LetterSpacing;

  @include tablet {
    font-size: $desktopH2FontSize;
    line-height: $desktopH2LineHeight;
  }
}

@mixin titleH3 {
  @include commonTitle;
  font-size: $mobileH3FontSize;
  line-height: $mobileH3LineHeight;
  letter-spacing: $h2LetterSpacing;

  @include tablet {
    font-size: $desktopH3FontSize;
    line-height: $desktopH3LineHeight;
  }
}

@mixin titleH4 {
  @include commonTitle;
  font-size: $mobileH4FontSize;
  line-height: $mobileH4LineHeight;
  letter-spacing: $h4LetterSpacing;

  @include tablet {
    font-size: $desktopH4FontSize;
    line-height: $desktopH4LineHeight;
  }
}

@mixin lgSubtitle {
  @include commonSubtitle;
  @include middleFont;
}

@mixin mdSubtitle {
  @include commonSubtitle;
  @include regularFont;
}

@mixin smSubtitle {
  @include commonSubtitle;
  @include smallFont;
}

@mixin lgParagraph {
  @include commonParagraph;
  @include middleFont;
}

@mixin mdParagraph {
  @include commonParagraph;
  @include regularFont;
}

@mixin smParagraph {
  @include commonParagraph;
  @include smallFont;
}

@mixin button {
  @include commonTitle;
  min-width: 180px;
  width: 100%;
  line-height: 24px;
  text-transform: uppercase;
  border-radius: $borderRadiusDefault;
  transition: $transitionDuration all ease-in-out;
  cursor: pointer;
}

@mixin linkLikeButton {
  @include button;
  color: $secondary100;
  background-color: $white100Base;
  border: none;

  &:hover {
    color: $primary120;
  }
}

@mixin link {
  letter-spacing: $defaultLetterSpacing;
  color: $primary100;
  text-decoration: none;
}

@mixin linkSm {
  @include smallFont;
}

@mixin linkMd {
  @include regularFont;
}

@mixin selectDropdown {
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 16px rgba(24, 25, 27, 0.08);
  border-radius: 4px;
  padding: 0 0;
  position: absolute;
  background: $white100Base;
  z-index: $inputDropdownIndex;
  top: 56px + 8px;
  list-style-type: none;
  width: 100%;
  max-height: 312px;

  li {
    padding: 10px 20px;
    color: $secondary100;
    cursor: pointer;
    @include robotoBold;
    @include middleFont;
  }
}

@mixin form {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
  margin-bottom: 36px;

  @include laptop {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }
}

@mixin errorMessage {
  position: absolute;
  bottom: 0;
  transform: translateY(100%);
  color: $red100Base;
  z-index: 3;
}

@mixin popupContent {
  max-width: 311px;
  width: 100%;
  background-color: $white100Base;
  border-radius: $borderRadiusDefault;
  padding: 32px;
  text-align: left;

  @include tablet {
    max-width: 848px;
    padding: 32px 80px 40px;
  }
}
