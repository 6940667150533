.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 165px;
  height: 40px;
  margin-bottom: 16px;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
