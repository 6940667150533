@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @include laptop {
    flex-direction: row;
  }
}

.divider {
  margin: 35px auto;

  @include laptop {
    margin: 65px 35px 0 35px;
  }
}

.button {
  max-width: 150px;
  margin-bottom: 25px;
}
