@import 'src/styles/variables';

.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;
  margin-top: 24px;
}

.paragraph {
  color: $secondary80;
}
