@import 'styles/mixins';

.container {
  & > div {
    @include mdParagraph;
  }
}

.title {
  margin-bottom: 9px;
}

.description {
  @include mdSubtitle;
  margin-bottom: 10px;

  @include laptop {
    @include titleH4;
    margin-bottom: 20px;
  }
}
