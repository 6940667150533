@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.collapsibleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 32px;
  width: 100%;
  border-bottom: 1px solid $primary20;
  overflow: hidden;
  transition: all $transitionDuration ease-in-out;
  height: 48px;
}

.active {
  height: calc((24px * 5));
}

.itemsWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  align-items: flex-start;

  @include tablet {
    width: 100%;
    flex-direction: row;
    justify-content: center;
    column-gap: 50px;
    align-items: center;
  }
}

.openButton {
  cursor: pointer;
  transition: all $transitionDuration ease-in-out;
}
