@import 'styles/media';

.pageTitle {
  margin-bottom: 16px;
}

.pageDescription {
  margin-bottom: 32px;
}

.pageInput {
  margin-bottom: 60px;

  @include tablet {
    margin-bottom: 40px;
  }
}

.supportCenterLink {
  margin-left: 0.5rem;
  font-size: 12px;
}
