@import 'src/styles/variables';
@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  background: $white100Base;
  box-shadow: 0 4px 16px rgba(24, 25, 27, 0.08);
  border-radius: 4px;
  padding: 24px 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 10px;

  .variableContainer:nth-child(2n) {
    justify-content: flex-end;
    max-width: max-content;
    justify-self: flex-end;
  }

  @include tablet {
    box-shadow: none;
    padding: 16px 0;
    border-bottom: 1px solid $primary20;
    grid-template-columns: $paymentHistoryGridTemplate;

    .variableContainer:nth-child(2n) {
      justify-content: flex-start;
      justify-self: unset;
      max-width: unset;
    }

    .variableContainer:nth-child(4n) {
      justify-content: flex-end;
    }
  }
}

.variableContainer {
  display: flex;
}

.variableName {
  letter-spacing: -0.2px;
  color: $secondary40;
  margin-right: 4px;
  @include regularFont;
  @include robotoSemiNormal;

  @include tablet {
   display: none;
  }
}

.variableValue {
  letter-spacing: -0.2px;
  color: $secondary80;
  @include regularFont;
  @include robotoSemiNormal;
  max-width: 90px;
  text-align: end;

  @include tablet {
    max-width: unset;
    text-align: unset;
  }
}

.paymentType {
  width: 90px;
  text-align: end;

  @include tablet {
    width: unset;
    text-align: unset;
  }
}

.amount {
  @include robotoBold;
}
