@import 'styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 45px;
}

.message {
  @include extraMiddleFont;
  @include extraLight;
  color: $secondary100;
  font-variant: all-small-caps;
  text-align: center;
  letter-spacing: 0.2px;
}
