@import 'src/styles/variables';
@import 'src/styles/media';
@import 'src/styles/mixins';

.dropzone {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  cursor: pointer;
  border: 1px dashed $secondary30;

  @include tablet {
    height: 120px;
  }
}

.draggingDropzone {
  border: 1px dashed $primary100;

  & svg path {
    fill: $primary100;
  }
}

.inputDescription {
  margin-left: 4px;
  color: $secondary40;
}

.draggingInputDescription {
  color: $primary100;
}

.uploadedFileContainer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.fileInfo {
  width: 100%;
}

.fileSize {
  color: $secondary40;
}

.deleteFileButton {
  cursor: pointer;
}

.errorMessage {
  color: $red100Base;
  margin-top: 8px;
}
