@import 'src/styles/colors';
@import 'src/styles/mixins';

.content {
  @include form;
  margin-bottom: 0;
  padding-top: 24px;
  padding-bottom: 18px;
}

.headerControls {
  @include lgParagraph;
  color: $primary100;
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  & > svg {
    margin-right: 8px;
  }
}

.addCoborrower {
  position: relative;
  top: 1px;
}

.headerControlsActive {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.coborrowerTitle {
  @include titleH3;
  color: $secondary100;
}

.removeButton {
  @include lgParagraph;
  color: $primary100;
  cursor: pointer;
}
