$maxMobile: 712px;
$maxPopUp: 1160px;
$maxTablet: 936px;
$maxLaptop: 1224px;

$minTablet: 712px;
$minLaptop: 936px;
$maxPopUp: 1160px;
$minScreen: 1224px;

@mixin tablet {
  @media (min-width: $minTablet) {
    @content;
  }
}

@mixin popup {
  @media (min-width: $maxPopUp) {
    @content;
  }
}

@mixin laptop {
  @media (min-width: $minLaptop) {
    @content;
  }
}

@mixin screen {
  @media (min-width: $minScreen) {
    @content;
  }
}
