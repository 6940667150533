@import 'styles/media';

.waitingWrapper {
  padding: 48px 32px 0;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: auto;

  & > h2 {
    text-align: center;
  }

  & > div {
    margin: 0;
    transform: scale(1.5);
  }

  @include tablet {
    justify-content: center;
  }
}
