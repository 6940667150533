@import 'styles/mixins';

.mainContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
}

.mainContentTitle {
  @include titleH4;
  margin-bottom: 7px;

  @include laptop {
    @include titleH2;
    margin-bottom: 7px;
  }
}

.mainContentDescription {
  @include mdSubtitle;
  text-align: center;
  margin-bottom: 20px;

  @include laptop {
    @include titleH4;
  }
}
