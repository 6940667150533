.container {
  width: 100%;
  max-width: 912px;
  margin: 0 auto;
  box-sizing: border-box;
  flex: 1 1 auto;
}

.smallPadding {
  padding: 16px 32px 40px;
}

.bigPadding {
  padding: 48px 32px 40px;
}

.removePaddingTop {
  padding-top: 0;
}

.removePaddingBottom {
  padding-bottom: 0;
}
