@import 'src/styles/mixins';
@import 'src/styles/colors';
@import 'src/styles/variables';

.inputContainer {
  position: relative;
  display: flex;
  flex-direction: column;

  &>.errorMessage {
    @include errorMessage;
  }
}

.labelError {
  color: $red100Base;
}

.inputWrapper {
  position: relative;
  max-height: 56px;
  width: 100%;
  overflow: hidden;

  input {
    @include lgParagraph;
    width: 100%;
    height: 100%;
    padding: 24px 16px 8px;
    border: 1px solid $secondary20;
    border-radius: $borderRadiusDefault;
    transition: all $transitionDuration ease-in-out;

    &:hover:not(:disabled) {
      border-color: $primary60;
    }

    &:focus:not(:disabled) {
      border-color: $primary100;
    }

    &:focus,
    &:not(:placeholder-shown) {
      &+label {
        @include smParagraph;
        color: $secondary60;
        top: 0;
        transform: translate(17px, 4px);
      }
    }

    &::placeholder {
      opacity: 0;
    }

    &+label {
      @include mdParagraph;
      position: absolute;
      top: 0;
      left: 0;
      z-index: $labelZIndex;
      transform: translate(17px, 18px);
      transition: all $transitionDuration ease-in-out;
    }
  }

  &.error>input {
    border-color: $red100Base;

    &+label {
      color: $red100Base;
    }
  }

  label {
    pointer-events: none;
  }
}

.inputIconWrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: transparent;
  cursor: pointer;
  transition: all $transitionDuration ease-in-out;

  &:active {
    scale: 0.8;
  }
}

.errorMessage {
  @include smParagraph;
  margin-top: 2px;
  color: $red100Base;
}

.popup {
  background-color: $white100Base;
  border-radius: $borderRadiusDefault;
  padding: 24px;
}
