@import 'src/styles/colors';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.primary {
  @include button;
  color: $white100Base;
  background-color: $primary100;

  &:hover:not(.disabled),
  &:focus {
    background-color: $primary120;
  }

  &.disabled,
  &:disabled {
    background-color: $secondary20;
  }

  &.disabled {
    &:hover {
      background-color: $secondary20;
      cursor: pointer;
    }
  }

  &:disabled {
    &:hover {
      background-color: $secondary20;
      cursor: auto;
    }
  }

  & > .loading > svg {
    fill: $white100Base;
  }
}

.secondary {
  @include button;

  color: $primary100;
  background-color: $white100Base;
  border: 1px solid $primary100;

  &:hover:not(.disabled),
  &:focus {
    background-color: $primary120;
    color: $white100Base;

    & > .loading > svg {
      fill: $white100Base;
    }
  }

  &.disabled {
    color: $secondary40;
    border-color: $secondary20;
  }
}

.withoutOutlines {
  @include button;
  color: $primary100;
  background-color: $white100Base;
  border: none;

  &:hover:not(.disabled),
  &:focus {
    background-color: $primary100;
    color: $white100Base;

    & > .loading > svg {
      fill: $white100Base;
    }
  }

  &.disabled {
    color: $secondary40;
  }
}

.menuButton {
  @include linkLikeButton;
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  justify-items: center;
  min-width: unset;
  width: unset;
  padding: 0;
  column-gap: 10px;
  cursor: pointer;

  &:hover {
    p {
      color: $primary120;
    }
    svg {
      transition: $transitionDuration all ease-in-out;
      fill: $primary120;
    }
  }
}

.linkLikeButton {
  @include linkLikeButton;
  color: $primary100;
}

.large {
  padding: 16px 32px;
  letter-spacing: 0.5px;
  @include regularFont;
}

.middle {
  padding: 8px 16px;
  @include smallFont;
  letter-spacing: $defaultLetterSpacing;
}

.linkLikeSize {
  padding: 16px 0;
  @include regularFont;
}
