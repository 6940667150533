.slick-slider .slick-list {
  padding: 0 24px;

  & > .slick-track > .slick-slide {
    transition: all 0.25s ease-in-out;
    margin-bottom: 1rem;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }

    &:not(.slick-active) {
      transform: scale(0.9);
    }
  }
}

.onboarding .slick-track {
  animation: animation 3s infinite;
  transition: transform 0.25s ease-in-out;
}

@keyframes animation {
  9% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-1.5%);
  }
  100% {
    transform: translateX(0%);
  }
}
