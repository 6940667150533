@import 'src/styles/media';
@import 'src/styles/mixins';

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 24px;
  }
}

.closeIcon {
  cursor: pointer;
}

.availableForRequestContainer {
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 24px;
  }
}

.availableForRequestAmount {
  margin-left: 8px;
  letter-spacing: -0.2px;
  @include regularFont;
  @include robotoBold;
  color: $secondary80;
}

.agreeButton {
  max-width: 408px;
}

.requestFundsInput {
  margin-bottom: 24px;
}
