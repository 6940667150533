@import 'src/styles/mixins';

.lgParagraph {
  @include lgParagraph;
}

.mdParagraph {
  @include mdParagraph;
}

.smParagraph {
  @include smParagraph;
}

.tinyMarginBottom {
  margin-bottom: 8px;
}

.smMarginBottom {
  margin-bottom: 16px;
}

.mdMarginBottom {
  margin-bottom: 24px;
}

.lgMarginBottom {
  margin-bottom: 32px;
}
