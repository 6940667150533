@import 'src/styles/variables';
@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  padding: 12px 12px 12px 16px;
  position: fixed;
  bottom: 48px;
  background: $secondary6;
  border: 1px solid $secondary30;
  border-radius: 8px;
  width: 91vw;
  max-width: 896px;
  display: flex;
  align-items: start;
  justify-content: space-between;
  left: 50%;
  transform: translateX(-50%);
  z-index: $pageNotificationZIndex;

  @include tablet {
    bottom: 80px;
  }
}

.content {
  @include middleFont;
  @include robotoSemiNormal;
  color: $secondary100;
}

.closeIcon {
  margin-top: 4px;
}
