.offersWrapper {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 24px;

  & > div {
    max-width: 261px;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 1rem;

  & > button {
    max-width: 120px;
  }
}
