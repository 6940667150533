@import 'src/styles/colors';
@import 'src/styles/variables';
@import 'src/styles/mixins';

.inputContainer {
  display: flex;
  column-gap: 14px;
  margin-bottom: 32px;
}

.inputContainerError {
  margin-bottom: 2px;
}

.input {
  width: 40px;
  height: 48px;
  text-align: center;
  border: 1px solid $secondary20;
  background-color: $white100Base;
  color: $secondary100;
  outline: none;
  @include robotoBold;
  @include largeFont;
}

.errorInput {
  border-color: $red100Base;
}

.errorText {
  color: $red100Base;
  margin-bottom: 12px;
}
