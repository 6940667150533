@import 'src/styles/mixins';
@import 'src/styles/colors';

.container {
  background: $white100Base;
  box-shadow: 0 4px 16px rgba(24, 25, 27, 0.08);
  border-radius: 4px;
  padding: 24px 16px;

  @include tablet {
    border-radius: 0;
    box-shadow: none;
    padding: 32px 0;
    border-bottom: 1px solid $secondary40;
  }
}

.titleContainer {
  @include tablet {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
  }
}

.title {
  margin-bottom: 8px;

  @include tablet {
    margin-bottom: 0;
  }
}

.amount {
  letter-spacing: -0.2px;
  color: $secondary80;
  @include regularFont;
  @include robotoSemiNormal;
}

.label {
  @include smallFont;
  @include robotoBold;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  background: $orange;
  padding: 4px 16px;
  border-radius: 4px;
  color: $white100Base;
  display: inline-block;
  margin-top: 20px;

  @include tablet {
    margin-top: 0;
  }
}

.mobilAmount {
  @include tablet {
    display: none;
  }
}

.desktopAmount {
  display: none;
  @include tablet {
    display: block;
  }
}

