@import 'src/styles/media';
@import 'styles/mixins';

.form {
  @include form;
  margin-bottom: 24px;
}

.buttonsContainer {
  margin-top: 40px;
}
