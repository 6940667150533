@import 'src/styles/colors';
@import 'src/styles/mixins';

.link {
  @include link;
}

.linkSm {
  @include linkSm;
}

.linkMd {
  @include linkMd;
}
