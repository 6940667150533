@import 'src/styles/colors';
@import 'src/styles/mixins';

.container {
  padding: 24px 0;
  border-top: 1px solid $primary20;
  border-bottom: 1px solid $primary20;
}

.disclosureItem {
  @include link;
  @include linkSm;
  cursor: pointer;
}

.separator {
  color: $secondary100;
}
