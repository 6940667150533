@import 'src/styles/colors';

.toastContainer:global(.Toastify__toast-container) {
  --toastify-toast-width: 344px;
  margin-right: 40px;

  & :global(.Toastify__toast-body) {
    padding: 15px 16px 15px 12px;
    background-color: $primaryBackground;
    height: 100%;
    margin: 0;
  }

  & :global(.Toastify__toast) {
    padding: 0;
    height: auto;
    border-radius: 1px;
    align-items: center;
    min-width: 344px;
    background-color: $primaryBackground;
  }
}

.textBody {
  color: $secondary80;
  display: flex;
  align-items: center;
  position: relative;
}
