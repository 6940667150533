@import 'src/styles/media';
@import 'src/styles/mixins';

.datesContainer {
  margin-bottom: 24px;

  p:first-child {
    margin-bottom: 8px;
  }

  @include tablet {
    display: flex;
    align-items: center;

    p:first-child {
      margin-right: 16px;
      margin-bottom: 0;
    }
  }
}
.pageTitle {
  margin-top: 24px;

  @include tablet {
    margin-top: 30px;
  }
}

.date {
  letter-spacing: -0.2px;
  color: $secondary80;
  @include smallFont;
  @include robotoSemiNormal;
}

.status {
  color: $white100Base;
  text-transform: uppercase;
  padding: 4px 24px;
  margin-bottom: 24px;
  background-color: $primary100;
  text-align: center;
  @include smallFont;
  @include robotoBold;

  @include tablet {
    margin-bottom: 0;
  }
}

.monetaryBoxesContainer {
  display: grid;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 32px;

  @include tablet {
    gap: 0;
    margin-bottom: 40px;
  }
}

.paymentsHistoryContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  margin-bottom: 40px;

  @include tablet {
    grid-row-gap: 0;
    margin-bottom: 32px;
  }
}

.tableTitle {
  display: none;

  @include tablet {
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: $paymentHistoryGridTemplate;
  }
}

.tableTitle {
  .tableColumnName:nth-child(4n) {
    text-align: end;
  }
}

.tableColumnName {
  letter-spacing: -0.2px;
  color: $secondary40;
  @include regularFont;
  @include robotoSemiNormal;
}

.logo {
  max-width: 98px;
}

.bottomBox {
  margin-top: 32px;
}

.applicationVariables {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 4px;

  @include tablet {
    grid-template-columns: 29% 34% 28% 9%;
    grid-gap: 0 4px;
  }
}

.variableName {
  @include robotoSemiNormal;
  @include regularFont;
  letter-spacing: -0.2px;
  color: $secondary80;
  margin-bottom: 4px;
}

.variableValue {
  @include robotoBold;
  @include regularFont;
  letter-spacing: -0.2px;
  color: $secondary100;
}

.monetaryBoxWithoutBoxShadow {
  box-shadow: none;
  padding: 0;
}

.jobAmountTitleFontSize {
  @include extraMiddleFont;
}
