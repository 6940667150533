.buttonWrapper {
  margin: 0 auto;
  max-width: 408px;
  margin-bottom: 40px;
}

.seeAllOffersButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
}

.button {
  max-width: 200px;
}
