@import 'src/styles/colors';
@import 'src/styles/mixins';

.addressInputContainer {
  position: relative;
  width: 100%;
}

.addressSuggestionsList {
  @include selectDropdown;
}

.addressAndZipInputsCombined {
  display: flex;
  width: 100%;
}

.inputMaxWidthWithRightMargin {
  max-width: 188px;
  margin-right: 32px;
}

.inputMaxWidth {
  max-width: 188px;
}
