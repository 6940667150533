@import 'styles/mixins';

.lgSubtitle {
  @include lgSubtitle;
}

.mdSubtitle {
  @include mdSubtitle;
}

.smSubtitle {
  @include smSubtitle;
}
