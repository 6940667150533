@import 'src/styles/media';
@import 'src/styles/colors';
@import 'src/styles/mixins';

.header {
  @include middleFont;
  @include robotoSemiNormal;
  color: $primary100;
  display: inline-flex;
  cursor: pointer;

  >svg {
    margin-left: 8px;
  }
}

.svgRotate {
  rotate: 180deg;
}

.content {
  margin-top: 16px;
  margin-bottom: 32px;

  @include laptop {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

.sources {
  @include form;
  grid-row-gap: 0px;
  padding-top: 16px;
}

.source {
  @include robotoSemiNormal;
  @include regularFont;
  color: $secondary100;
  margin: 4px 0;
  display: inline-block;
  margin-left: 10px;
}

.dotContainer {
  height: 18px;
}

.dot {
  display: inline-block;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: $secondary100;
  margin-left: 9px;
  align-self: center;
}

.sourceContainer {
  display: flex;
  align-items: flex-start;
}
