@import 'src/styles/media';
@import 'src/styles/mixins';

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 24px;
  }
}

.projectBalanceAmount {
  letter-spacing: -0.2px;
  color: $secondary80;
  margin-bottom: 6px;
  @include regularFont;
  @include robotoBold;

  @include tablet {
    margin-bottom: 16px;
  }
}

.closeDescription {
  @include regularFont;
  @include robotoSemiNormal;
  margin-bottom: 20px;

  @include tablet {
    @include middleFont;
    margin-bottom: 28px;
  }
}

.closeDescriptionAmount {
  @include robotoBold
}

.closeButton {
  max-width: 408px;
}

.popupTitle {
  margin-bottom: 24px;
}

.popupContentContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 24px;
}

.popupParagraph {
  letter-spacing: -0.2px;
  color: $secondary100;
  @include regularFont;
  @include robotoSemiNormal;

  @include tablet {
    @include middleFont;
  }
}

.popupDescriptionHighlighted {
  @include robotoBold;
}
