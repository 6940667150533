@import 'src/styles/mixins';

.container {
  border: 1px solid $primary20;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 40px;

  @include tablet {
    padding: 40px;
  }
}

.content {
  width: 100%;
  max-width: 408px;
  margin: 0 auto;
}

.title {
  @include titleH3;
  margin-bottom: 16px;

  @include tablet {
    margin-bottom: 8px;
  }
}

.paragraph {
  @include mdParagraph;
  margin-bottom: 40px;

  @include tablet {
    @include lgParagraph;
  }
}
