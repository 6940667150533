@import 'src/styles/mixins';

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto 26px;

  @include laptop {
    padding: 0 126px;
  }
}

.step {
  @include smallFont;
  @include robotoSemiNormal;
  color: $white100Base;
  padding: 8px;
  text-align: center;
  background: $primary100;
  border-radius: 2px;
  letter-spacing: $defaultLetterSpacing;
  flex-grow: 1;
  align-self: stretch;
  display: flex;
  align-items: center;
  justify-content: center;

  @include laptop {
    letter-spacing: $defaultLetterSpacing;
    @include middleFont;
    @include robotoSemiNormal;
  }
}

.arrows {
  display: flex;
  flex-direction: column;
  padding: 0 3px;

  @include laptop {
    padding: 0 16px;
  }

  svg {
    &:first-child {
      margin-bottom: 2px;
    }
  }
}

.arrowIcon {
  width: 10px;
  height: auto;

  @include laptop {
    width: 16px;
  }
}
