@import 'styles/colors';

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  max-height: 20px;
  margin: auto;

  & > svg {
    fill: $primary100;
  }
}
