@import 'src/styles/mixins';
@import 'src/styles/colors';

.container {
  display: flex;
  align-items: center;
}

.switchLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55px;
  height: 30px;
  background: $secondary10;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
  cursor: pointer;
}

.switchLabelChecked {
  background: $primary100;
}

.switchButton {
  content: '';
  position: absolute;
  top: 3px;
  left: 2px;
  width: 24px;
  height: 24px;
  border-radius: 45px;
  transition: 0.2s;
  background: $white100Base;
}

.switchButtonChecked {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.text {
  @include mdParagraph;
  margin-left: 12px;
}
