@import 'styles/colors';
@import 'styles/mixins';
@import 'styles/variables';

.menuItemText {
  @include mdSubtitle;
  text-transform: capitalize;
  line-height: 24px;
  transition: $transitionDuration all ease-in-out;
}

.activeMenuItem {
  .menuItemText {
    color: $primary100;
  }

  & > svg {
    fill: $primary100;
  }
}
