@import 'src/styles/mixins';

.headerContainer {
  display: flex;
  justify-content: flex-start;
}

.contentClassName {
  margin-bottom: 44px;
}

.form {
  @include form;
  padding-top: 24px;
}

.inputIconWrapper {
  left: 318px;
}

.headerContent {
  display: flex;
  align-items: center;
}

.tooltip {
  z-index: 2;
  margin-left: 11px;
}
