@import 'styles/mixins';

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}

.pageTitle {
  @include titleH1;
}

.pageDescription {
  @include mdParagraph;
}
