@import 'src/styles/media';
@import 'src/styles/mixins';

.container {
  padding: 16px 0;
  border-bottom: 1px solid #D1D1D1;

  @include tablet {
    padding: 24px 0;
  }
}

.title {
  @include regularFont;
  color: $secondary60;
  margin-bottom: 4px;
}

.valueContainer {
  display: flex;
  align-items: center;
}

.value {
  @include lgParagraph;
  font-weight: $boldFontWeight;
}

.editContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: 16px;

  @include tablet {
    margin-left: 24px;
  }
}

.editTitle {
  @include lgParagraph;
  color: $primary100;
  font-weight: $boldFontWeight;
}
