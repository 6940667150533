@import 'src/styles/variables';
@import 'src/styles/media';
@import 'src/styles/mixins';

.popupTitle {
  margin-bottom: 24px;
}

.popupParagraph {
  letter-spacing: -0.2px;
  color: $secondary100;
  margin-bottom: 24px;
  @include regularFont;
  @include robotoSemiNormal;

  @include tablet {
    @include middleFont;
  }
}
