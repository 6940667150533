@import 'styles/mixins';

.title {
  @include lgSubtitle;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 24px;
}

.description {
  @include smParagraph;
  text-align: center;
}

.bottomBoxContainer {
  margin-top: auto;
  padding: 16px 34px 24px;
}

.intermediaryBottomBoxContainer {
  max-width: 500px;
  margin: 0 auto;
  padding: 16px 34px 24px;
}

.intermediaryTitle {
  @include titleH4;
  text-align: center;
  margin-bottom: 7px;

  @include laptop {
    @include titleH2;
    margin-bottom: 27px;
  }
}

.intermediaryDescription {
  @include mdSubtitle;
  text-align: center;
  margin-bottom: 20px;

  @include laptop {
    @include titleH4;
    margin-bottom: 40px;
  }
}

.borrowerFormInput {
  margin-bottom: 5px;
}

.intermediaryFormInputsContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.intermediaryFormInput {
  max-width: 236px;
  margin-bottom: 24px;
}
