@import 'src/styles/media';
@import 'src/styles/variables';
@import 'styles/mixins';

.guideContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  @include laptop {
    margin-bottom: 44px;
  }
}

.subTitle {
  display: flex;
  margin-bottom: 15px;

  & > div {
    @include titleH3;
  }

  & > svg {
    position: relative;
    bottom: 6px;
    left: 2px;
    cursor: pointer;
  }
}

.mainContent {
  @include laptop {
    margin: 0 60px;
  }
}

.poweredBy {
  display: flex;
  align-items: center;

  & > span {
    @include smSubtitle;
  }
}
