@import 'styles/mixins';

.requirementList {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-left: 24px;
  margin-bottom: 32px;
}

.requirementItem {
  @include lgParagraph;
  list-style: disc;
  &::marker {
    display: block;
  }
}
