@import 'src/styles/mixins';

.applicationsContainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;

  @include tablet {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;
  }
}

.activeApplications {
  margin-bottom: 40px;
}

.noApplications {
  margin-top: 16px;
  letter-spacing: -0.2px;
  color: $secondary80;
  @include regularFont;
  @include robotoSemiNormal;
}

.collapseTrigger {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;
}

.arrowIsOpen {
  transform: rotate(180deg);
}
