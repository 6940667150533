@import 'styles/mixins';

.container {
  @include smParagraph;
  margin-bottom: 10px;

  @include laptop {
    @include lgParagraph;
    margin-bottom: 35px;
  }
}

.bold {
  @include robotoBold;
}

.activeText {
  @include smParagraph;
  color: $primary100;
  cursor: pointer;
}

.additionalInfo {
  @include smParagraph;
  display: block;

  @include laptop {
    display: inline;
  }
}

.info {
  @include mdParagraph;
  color: $secondary80;

  @include laptop {
    @include lgParagraph;
  }
}

.lineHeight24 {
  @extend .info;
}

.marginBottom24 {
  margin-bottom: 24px;
}

.marginBottom27 {
  margin-bottom: 27px;
}
