@import 'src/styles/media';
@import 'src/styles/mixins';
@import 'src/styles/variables';

.status {
  @include smSubtitle;
  text-transform: uppercase;
  color: $white100Base;

  padding: 3px 0px;
  background-color: $secondary100;
  display: flex;
  justify-content: center;
  margin-bottom: 32px;
}

.title {
  @include titleH3;
  color: $secondary100;
  margin-bottom: 16px;
}

.info {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  margin-bottom: 24px;

  @include laptop {
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 32px;
  }
}

.infoWithOriginationFee {
  @extend .info;

  @include laptop {
    grid-template-columns: repeat(5, 1fr);
  }
}

.detail {
  display: flex;
  flex-direction: column;
}

.key {
  @include mdParagraph;
  color: $secondary80;
}

.value {
  @include mdParagraph;
  color: $secondary100;
  margin-top: 4px;
  font-weight: $boldFontWeight;
}

.labelWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 4px;
  margin-bottom: 16px;
}

.label {
  @include mdSubtitle;
  padding: 4px 8px;
  border-radius: $borderRadiusDefault;
  background-color: #ff954f;
}

.submitButton {
  @include laptop {
    max-width: 220px;
  }
}

.agreementButton {
  max-width: 408px;
  margin: 0 0 24px;

  @include laptop {
    max-width: 340px;
    margin: 0 auto 0 20px;
  }
}
