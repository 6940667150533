@import 'styles/media';

.pageWrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  @include tablet {
    max-width: 408px;
    margin: 0 auto;
  }
}
