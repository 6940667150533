@import 'styles/media';
@import 'styles/colors';

.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding: 40px 0;

  @include tablet {
    padding: 116px 0 140px;
  }
}

.noOffersIcon {
  margin-bottom: 35px;
}

.noOffersTitle {
  margin-bottom: 16px;
  color: $red100Base;

  @include tablet {
    margin-bottom: 24px;
  }
}

.noOffersDescription {
  margin-bottom: 16px;
  text-align: center;
}

.noOffersSubDescription {
  margin-bottom: 40px;
}

.noOffersButton {
  margin-bottom: 40px;
  max-width: 408px;
}
